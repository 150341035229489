import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/SEO"
import SimpleBanner from "../../components/SimpleBanner/SimpleBanner"
import { Link } from "gatsby"

const Container = styled.div`
  max-width: 80ch;
  margin: 80px auto 0px;
  text-align: left;
  padding: 1em;

  h2 {
    color: var(--primary);
    font-size: var(--h2);
  }

  .link-container {
    color: var(--primary);
    font-weight: bold;
    text-align: center;
  }

  span {
    color: var(--primary);
  }

  p {
    line-height: 1.6em;
  }
`

const organisation_item = () => {
  return (
    <>
      <Seo title="Wynajem samochodu do ślubu" />
      <Layout>
        <SimpleBanner title="Wynajem samochodu do ślubu">
          <StaticImage
            className="banner__image desktop"
            src="../../images/organisation/wynajem.jpg"
          />
          <StaticImage
            className="banner__image mobile"
            src="../../images/organisation/wynajem-thumb.jpg"
          />
        </SimpleBanner>
        <Container>
          <p>
            Świadczymy profesjonalne usługi w zakresie wynajmu samochodów z
            kierowcą na ślub i inne uroczystości. W swej ofercie posiadamy
            bogaty wybór wysokiej klasy samochodów. Naszą misją jest dbanie o
            najwyższą jakość świadczonych przez nas usług i idące za tym
            zadowolenie Klientów. Posiadamy wieloletnie doświadczenie, które
            zaprocentowało wieloma pozytywnymi opiniami. Limuzyny prowadzą
            doświadczeni, kulturalni i odpowiednio ubrani kierowcy. Na życzenie
            Klientów, zapewniamy gustowne wystrojenie samochodów.
          </p>

          <p className="link-container">
            <Link to="/contact">Zapytaj nas o szczegóły</Link>
          </p>
        </Container>
      </Layout>
    </>
  )
}

export default organisation_item
